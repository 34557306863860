import { useState, useEffect } from "react";

const useMicrophonePermissions = () => {
  const [hasMicrophoneAccess, setHasMicrophoneAccess] = useState(null);

  useEffect(() => {
    setHasMicrophoneAccess(false);
    const checkMicrophonePermissions = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({ audio: true });
        setHasMicrophoneAccess(true);
      } catch (err) {
        setHasMicrophoneAccess(false);
      }
    };

    checkMicrophonePermissions();
  }, []);

  return hasMicrophoneAccess;
};

export default useMicrophonePermissions;
