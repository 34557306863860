import React from "react";
import { motion } from "framer-motion";
import playIcon from "../assets/play-icon.png";

const Card = ({ title, description, imgUrl, videoUrl, link, showVideo, handleImageClick, index, tags }) => {
  return (
    <motion.div
      initial={"hidden"}
      whileInView={"visible"}
      variants={{
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
      }}
      className="max-w-xl bg-white rounded-lg border border-gray-200 shadow-lg dark:bg-gray-800 dark:border-gray-700 my-8"
    >
      <div onClick={() => handleImageClick(index)} className="relative cursor-pointer">
        {!showVideo ? (
          <>
            <img
              className="rounded-t-lg w-full h-48 object-cover"
              src={imgUrl}
              alt={title}
            />
            {videoUrl && (
              <img
                src={playIcon}
                alt="Play Video"
                className="absolute inset-0 w-16 h-16 m-auto"
              />
            )}
            <div className="absolute top-0 right-0 m-2 flex flex-col items-end">
              {tags.map((tag, idx) => (
                <span
                  key={idx}
                  className="bg-black text-white text-xs font-semibold mr-2 mb-1 px-2.5 py-0.5 rounded"
                  style={{ borderImage: "linear-gradient(80deg, #FCEE0C 30%, #000 30% 35%, #ff5bf8 35%) 100% 1", borderBottomWidth: "2px", boxShadow: "2px 0px 0px #ff5bf8"}}
                  // className="bg-blue-500 text-white text-xs font-semibold mr-2 mb-1 px-2.5 py-0.5 rounded"
                >
                  {tag}
                </span>
              ))}
            </div>
          </>
        ) : (
          <video
            className="rounded-t-lg w-full h-48 object-cover"
            src={videoUrl}
            controls
            autoPlay
          />
        )}
      </div>
      <div className="p-5">
        <a href={link}>
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            {title}
          </h5>
        </a>
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
          {description}
        </p>
        {link &&
          <a
            href={link}
            className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-black rounded-lg hover:bg-blue-200 focus:ring-4 focus:outline-none focus:ring-blue-300"
            style={{ borderImage: "linear-gradient(80deg, #FCEE0C 30%, #000 30% 35%, #ff5bf8 35%) 100% 1", borderBottomWidth: "2px", boxShadow: "2px 0px 0px #ff5bf8"}}
          >
            Go to Project
            <svg
              className="ml-2 -mr-1 w-4 h-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </a>
        }
      </div>
    </motion.div>
  );
};

export default Card;
