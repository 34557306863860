import React, { useContext, useState, useEffect, useCallback } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import useSpeechToText from "react-hook-speech-to-text";
import useMicrophonePermissions from "../components/MicrophonePermissionChecker"
import "../styles/Home.css";
import Typical from "react-typical";
import { contactLinks } from "../constants";
import { ThemeContext } from "../themeProvider";
import { motion } from "framer-motion";
import { Link } from "react-scroll";
import cyberPunkMe from "../assets/MeCyberpunk.png";
import pdfCV from "../assets/CV.pdf";

const Home = () => {
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;

  const hasMicrophoneAccess = useMicrophonePermissions();
  const [bypassMicrophoneAccess, setBypassMicrophoneAccess] = useState(false);

  const { unityProvider, isLoaded, loadingProgression, addEventListener, removeEventListener, sendMessage }
    = useUnityContext({
      loaderUrl: "build/Build.loader.js",
      dataUrl: "build/Build.data",
      frameworkUrl: "build/Build.framework.js",
      codeUrl: "build/Build.wasm",
      companyName: "Deniz Yunus Göğüş",
      productName: "Portfolio Website",
      productVersion: "0.1"
    });

  const {
    error,
    isRecording,
    results,
    startSpeechToText,
    stopSpeechToText,
  } = useSpeechToText({
    continuous: true,
    crossBrowser: true,
    googleApiKey: "AIzaSyCbEZypsALI0JpkMZzX51n_ZbnYxVV0SEw",
    useLegacyResults: false,
  });

  const loadingPercentage = Math.round(loadingProgression * 100);
  const [fileUrl, setFileUrl] = useState(null);

  const [devicePixelRatio, setDevicePixelRatio] = useState(
    window.devicePixelRatio
  );

  const handleByteArray = useCallback((byteArrayString) => {
    const byteArray = Uint8Array.from(atob(byteArrayString), c => c.charCodeAt(0));
    const blob = new Blob([byteArray], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);
    setFileUrl(url);

    if (isLoaded) {
      // Send the file URL back to Unity
      sendMessage('MyAvatarScriptsControllerCyborg', 'ReceiveFileUrl', url);

      // Clean up the previous blob if any
      if (fileUrl) {
        URL.revokeObjectURL(fileUrl);
      }
    }
  }, [fileUrl, isLoaded]);

  useEffect(() => {
    if (isLoaded) {
      addEventListener("SendByteArray", handleByteArray);
      return () => {
        removeEventListener("SendByteArray", handleByteArray);
      };
    }
  }, [addEventListener, removeEventListener, handleByteArray, isLoaded]);

  useEffect(() => {
    if (isLoaded) {
      addEventListener("StartSpeechRecognition", startSpeechToText);
      addEventListener("StopSpeechRecognition", stopSpeechToText);
      return () => {
        removeEventListener("StartSpeechRecognition", startSpeechToText);
        removeEventListener("StopSpeechRecognition", stopSpeechToText);
      };
    }
  }, [addEventListener, removeEventListener, isLoaded, startSpeechToText, stopSpeechToText]);

  useEffect(() => {
    if (results.length > 0) {
      const latestResult = results[results.length - 1].transcript;
      console.log("Transcribed: " + latestResult);
      if (latestResult !== "")
        sendMessage('MyAvatarScriptsControllerCyborg', 'GetResponse', latestResult);
      stopSpeechToText();
    }
  }, [results]);

  useEffect(
    function () {
      // A function which will update the device pixel ratio of the Unity
      // Application to match the device pixel ratio of the browser.
      const updateDevicePixelRatio = function () {
        setDevicePixelRatio(window.devicePixelRatio);
      };
      // A media matcher which watches for changes in the device pixel ratio.
      const mediaMatcher = window.matchMedia(
        `screen and (resolution: ${devicePixelRatio}dppx)`
      );
      // Adding an event listener to the media matcher which will update the
      // device pixel ratio of the Unity Application when the device pixel
      // ratio changes.
      mediaMatcher.addEventListener("change", updateDevicePixelRatio);
      return function () {
        // Removing the event listener when the component unmounts.
        mediaMatcher.removeEventListener("change", updateDevicePixelRatio);
      };
    },
    [devicePixelRatio]
  );

  const handleBypassMicrophoneAccess = () => {
    setBypassMicrophoneAccess(true);
  };

  if (error) return <p>Web Speech API is not available in this browser 🤷‍</p>;
  return (
    <>
      {hasMicrophoneAccess === false && !bypassMicrophoneAccess && (
        <div style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, zIndex: 1000 }} className="color-changing-background">
          <img src={cyberPunkMe} style={{ position: "absolute", top: "20vh", left: 0, height: "60vh", zIndex: -1 }} />
          <p style={{ textAlign: "center", marginTop: "50vh", fontSize: "24px" }}>
            Microphone access is required to use this application.
          </p>
          <button
            onClick={handleBypassMicrophoneAccess}
            style={{ display: "block", margin: "20px auto", padding: "10px 20px", fontSize: "18px" }}
          >
            Continue without microphone access
          </button>
        </div>
      )}
      <div>
        <main
          className="mx-auto max-w-7xl px-4 sm:px-6 md:mt-0 lg:px-8 flex flex-col md:flex-row items-center justify-center md:justify-between h-screen"
          id="/"
        >
          <div className="sm:text-center lg:text-left flex-shrink-0">
            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
              <motion.span
                className={darkMode ? "block text-black" : " text-white"}
              >
                Hi, I am Deniz
              </motion.span>
              <span className="block z-0 lg:inline">
                <Typical
                  steps={[
                    "Unity Developer",
                    1000,
                    "XR Enthusiast",
                    1000,
                    "Digital Nomad",
                    1000,
                  ]}
                  className="multicolortext"
                  loop={Infinity}
                />
              </span>
            </h1>
            <p
              className={
                darkMode
                  ? "mt-3 text-base text-black sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0"
                  : "mt-3 text-base text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0"
              }
            >
              I am a (primarily) Unity, C# Developer, with broad experience in many fields. (see About) <br />
              I am currently working at Monster Notebook as a Hardware/Desktop Developer.
            </p>
            <div className="flex md:justify-start ">
              {contactLinks.map((el) => (
                <a
                  href={el.link}
                  className="mr-5 cursor-pointer mt-8 hover:scale-125"
                  key={el.name}
                >
                  <img alt="" src={el.url} style={{width: "40px"}} />
                  {/* <p className="text-md mt-2 hover:hidden">{el.name}</p> */}
                </a>
              ))}
            </div>
            <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
              <div className="mt-3 sm:mt-0 cursor-pointer w-1/2">
                {/* <a className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-500 hover:bg-blue-200 md:py-4 md:text-lg md:px-10" href={pdfCV}> */}
                <a className="cyberpunk-button" style={{height: "60px", paddingLeft: "25px", fontSize: "30px", marginBottom: "10px" }} href={pdfCV}>
                  Resume
                </a>
                <div style={{ fontStyle: "italic", color: "white", width: "max-content" }} className="colored-bottom-line">Just think, do you still really need it?</div>
              </div>
            </div>
          </div>

          <div style={{ width: "40vw", height: "20vw", position: "relative", backgroundColor: "blue", borderRadius: "20px" }} >
            <div style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center", position: "absolute" }}>
              {isLoaded === false && (
                // We'll conditionally render the loading overlay if the Unity
                // Application is not loaded.
                <div className="loading-overlay">
                  <p>I was sleeping, let me get up... ({loadingPercentage}%)</p>
                </div>
              )}
            </div>
            <div className="shadow">

            </div>
            {hasMicrophoneAccess === true && !bypassMicrophoneAccess ? (
              <Unity className="unity" unityProvider={unityProvider} style={{ top: "0px", width: "40vw", height: "40vh", position: "absolute", borderRadius: "20px", borderColor: "#feff6e #ff5bf8 #ff5bf8 #feff6e", borderWidth: "4px 12px 12px 4px" }} devicePixelRatio={devicePixelRatio} />
            ) : (
              <div style={{ top: "0px", width: "40vw", height: "40vh", position: "absolute", borderRadius: "20px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "white" }}>
                <p style={{ textAlign: "center", fontSize: "18px" }}>Microphone access is required to use this feature.</p>
              </div>
            )}
            {/* <div style={{ backgroundColor: "red", width: "100%", height: "100%", position: "absolute" }}></div> */}
            {isRecording &&
              <div style={{ backgroundColor: "white", top: "80%", width: "30vw", textAlign: "center", position: "absolute", left: "10vw", borderRadius: "20px", opacity: "50%" }}>
                <p>
                  Microphone is being used, listening...
                </p>
              </div>
            }
          </div>

          {/* <motion.img
            initial="hidden"
            whileInView={"visible"}
            variants={{
              visible: {
                y: 0,
                opacity: 1,
                transition: {
                  type: "spring",
                },
              },
              hidden: { opacity: 1, y: 80 },
            }}
            src={heroBg}
            alt=""
            className="md:w-3/6 hidden sm:block"
          /> */}
        </main>
      </div>
    </>
  );
};

export default Home;
