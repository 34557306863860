import React, { useState, useContext } from "react";
import Card from "../components/Card";
import { ThemeContext } from "../themeProvider";
import { projectsData } from "../constants";

const Projects = () => {
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;

  const [videoStates, setVideoStates] = useState(projectsData.map(() => false));

  const handleImageClick = (index) => {
    setVideoStates((prevStates) =>
      prevStates.map((state, i) => (i === index ? !state : state))
    );
  };

  return (
    <div
      id="projects"
      // className={darkMode ? "bg-white text-black" : "bg-gray-900 text-white"}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-12">
      <div style={{ width: "100%", alignItems: "center", justifyContent: "center", alignContent: "center", display: "flex" }}>
          <h2
            className={
              theme.state.darkMode
                ? "text-5xl font-bold px-4 md:px-0 text-center colored-bottom-line"
                : "text-5xl font-bold px-4 md:px-0 text-center text-white colored-bottom-line"
            }
          >
            Projects
          </h2>
        </div>
        <h4 className="mt-16 text-3xl font-semibold multicolortext" style={{width: "max-content"}}>
          Debug.Log(builtProjects);
        </h4>
        <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {projectsData.map((service, index) => (
            <Card
              key={index}
              title={service.name}
              description={service.description}
              imgUrl={service.imgUrl}
              videoUrl={service.videoUrl}
              link={service.link !== undefined ? service.link : ""}
              showVideo={videoStates[index]}
              handleImageClick={service.videoUrl !== undefined ? handleImageClick : {}}
              index={index}
              tags={service.tags}
            />
          ))}
        </div>
        {/* <a
          href="hello"
          className="w-32 flex items-center py-4 px-4 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mx-auto mt-4"
        >
          Show More
          <svg
            className="ml-2 -mr-1 w-4 h-4"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </a> */}
      </div>
    </div>
  );
};

export default Projects;
