import linkedinIcon from "./assets/social/linkedin.png";
import instagramIcon from "./assets/social/instagram.png";
import spotifyIcon from "./assets/social/spotify.png";
import githubIcon from "./assets/social/github.png";

import holo3d from "./assets/holo3d.jpg";

import tunnelapp from "./assets/tunnelapp.jpg";
import tunnelappvideo from "./assets/tunnelappvideo.mp4";

import chatbot from "./assets/chatbot.jpg";
import chatbotVideo from "./assets/chatbot.mp4";

import weightapp from "./assets/weightapp.png";
import weightappvideo from "./assets/weightappvideo.mp4";

import rocketvr from "./assets/rocketvr.png";
import rocketvrvideo from "./assets/rocketvrvideo.mp4";

import vastview from "./assets/vastview.png";
import vastviewvideo from "./assets/vastviewvideo.mp4";

import skeleton from "./assets/skeleton.png"
import skeletonvideo from "./assets/skeletonvideo.mp4"

import cigarattesapp from "./assets/cigarattesapp.png"
import cigarattesappvideo from "./assets/cigarattesappvideo.mp4"

import colabubbles from "./assets/colabubbles.png"
import colabubblesvideo from "./assets/colabubblesvideo.mp4"

import chickensaber from "./assets/chickensaber.png"
import chickensabervideo from "./assets/chickensabervideo.mp4"

import iqoscanon from "./assets/iqoscanon.png"
import iqoscanonvideo from "./assets/iqoscanonvideo.mp4"

import colabackgroundprint from "./assets/colabackgroundprint.jpg";

import iqoscancer from "./assets/iqoscancer.jpeg"
import iqoscancervideo from "./assets/iqoscancervideo.mp4"

import tilt from "./assets/tilt.png"
import tiltvideo from "./assets/tiltvideo.mp4"

import barbiewindows from "./assets/barbiewindows.png"
import barbiewindowsvideo from "./assets/barbiewindowsvideo.mp4"

import boxing from "./assets/boxing.jpg"

import smartpot from "./assets/smartpot.png"

import cancernotetaking from "./assets/cancernotetaking.png"

import octo from "./assets/octo.png"

export const techStack = [

  { name: "Unity", link: "https://www.svgrepo.com/show/342325/unity.svg" },
  { name: "C#", link: "https://cdn.prod.website-files.com/6047a9e35e5dc54ac86ddd90/63065002cd563e1cd1cead28_eaadfe64.png" },
  { name: "Arduino", link: "https://cdn.worldvectorlogo.com/logos/arduino-1.svg" },
  { name: "Python", link: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Python-logo-notext.svg/1869px-Python-logo-notext.svg.png" },
  { name: "NodeJS", link: "https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original-wordmark.svg" },
  { name: "Javascript", link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg" },
  { name: "MySQl", link: "https://www.svgrepo.com/show/303251/mysql-logo.svg" },
  { name: "Mongo DB", link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-plain.svg" },
  { name: "React Native", link: "https://pagepro.co/blog/wp-content/uploads/2020/03/react-native-logo-884x1024.png" },
  { name: "Firebase", link: "https://www.vectorlogo.zone/logos/firebase/firebase-icon.svg" },
  { name: "Azure", link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/azure/azure-plain.svg" },
  { name: "Adobe XD", link: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c2/Adobe_XD_CC_icon.svg/1051px-Adobe_XD_CC_icon.svg.png" },
  { name: "Adobe Photoshop", link: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/af/Adobe_Photoshop_CC_icon.svg/1051px-Adobe_Photoshop_CC_icon.svg.png" },
  // { name: "HTML", link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg" },
  // { name: "CSS", link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-plain.svg" },
  // { name: "Bootstrap", link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bootstrap/bootstrap-plain.svg" },
  // { name: "Redux", link: "https://raw.githubusercontent.com/devicons/devicon/master/icons/redux/redux-original.svg" },
  // { name: "Sass", link: "https://raw.githubusercontent.com/devicons/devicon/master/icons/sass/sass-original.svg" },

  // { name: "React", link: "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original-wordmark.svg" },
  // { name: "Tailwind CSS", link: "https://www.vectorlogo.zone/logos/tailwindcss/tailwindcss-icon.svg" },
  // { name: "Heroku", link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/heroku/heroku-plain.svg" },
  // { name: "Git", link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-plain.svg" },
  // { name: "Docker", link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/docker/docker-plain.svg" },

  // { name: "Bash", link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bash/bash-original.svg" },
  // { name: "Vim", link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vim/vim-original.svg" },

  // { name: "VS Code", link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vscode/vscode-original.svg" },aaa
  // {name: "", link: "https://repository-images.githubusercontent.com/59065830/b62be480-45d2-11ea-9989-803db0f9c44d"}
]


export const contactLinks = [
  // { name: 'Stack Overflow', url: 'https://img.icons8.com/external-tal-revivo-color-tal-revivo/40/000000/external-stack-overflow-is-a-question-and-answer-site-for-professional-logo-color-tal-revivo.png', link: 'https://stackoverflow.com/users/11788531/aakash-sharma' },
  // { name: 'Github', url: 'https://img.icons8.com/doodle/40/000000/github--v1.png', link: 'https://github.com/DenizYunus' },
  // { name: 'LinkedIn', url: 'https://img.icons8.com/doodle/40/000000/linkedin--v2.png', link: 'https://www.linkedin.com/in/deniz-yunus-gogus/' },
  // { name: 'Instagram', url: 'https://img.icons8.com/doodle/40/000000/instagram-new--v2.png', link: 'https://www.instagram.com/deniz_yunus.gogus' },
  // { name: 'Spotify', url: 'https://img.icons8.com/doodle/40/000000/spotify--v1.png', link: "https://open.spotify.com/track/55phMMJiqMhc3IRlJvERPD?si=06b0919c5f134bd2" }
  { name: 'Github', url: githubIcon, link: 'https://github.com/DenizYunus' },
  { name: 'LinkedIn', url: linkedinIcon, link: 'https://www.linkedin.com/in/deniz-yunus-gogus/' },
  { name: 'Instagram', url: instagramIcon, link: 'https://www.instagram.com/deniz_yunus.gogus' },
  { name: 'Spotify', url: spotifyIcon, link: "https://open.spotify.com/track/55phMMJiqMhc3IRlJvERPD?si=06b0919c5f134bd2" }
]



export const serviceData = [
  {
    name: 'Custom Desktop Development',
    desc: "I offer comprehensive custom desktop development services, creating everything from immersive games to sophisticated dashboard applications. Leveraging C# and .NET, or Unity, I build robust and user-friendly software solutions tailored to meet the unique needs of each client. Whether you're looking for a high-performance desktop app or an engaging game, I deliver exceptional quality and performance.",
    img: "https://img.icons8.com/external-kiranshastry-lineal-color-kiranshastry/64/000000/external-web-development-coding-kiranshastry-lineal-color-kiranshastry.png"
  },
  {
    name: 'Custom Mobile Development',
    desc: "Specializing in Android development, I provide custom mobile solutions that cater to a variety of needs, from XR (Extended Reality) applications to standard mobile apps. With expertise in developing for all kinds of mobile devices, I ensure seamless user experiences and innovative features that make your app stand out in the competitive mobile landscape.",
    img: "https://img.icons8.com/external-justicon-lineal-color-justicon/64/000000/external-app-development-responsive-web-design-justicon-lineal-color-justicon.png"
  },

  {
    name: 'Custom Hardware Development',
    desc: "From small gadgets to extensive room-scale installations, I offer custom hardware development services using platforms like ESP32, Arduino, and Raspberry Pi. I design and build specialized hardware solutions that integrate seamlessly with your software, providing innovative and practical applications for various industries. (Note: I focus on design and development, not physical carpentry :))",
    img: "https://img.icons8.com/external-flaticons-flat-flat-icons/64/000000/external-backend-no-code-flaticons-flat-flat-icons.png"
  },
]



export const projectsData = [
  {
    name: "AI Character Chatbot",
    description: "OpenAI GPT powered, lipsync supported, custom character, with conversational abilities.",
    imgUrl: chatbot,
    videoUrl: chatbotVideo,
    link: "https://github.com/DenizYunus/Freelance_GPTAssistedLipSyncedChatbot",
    tags: ["AI", "Desktop", "Mobile", "WebGL"]
  },
  {
    name: "Hologram Streaming App",
    description: "WebRTC supported video communication application, shown in a triangular pyramid holographic device.",
    imgUrl: holo3d,
    link: "https://github.com/DenizYunus/StreamingAppWebRTC",
    tags: ["WebRTC", "Desktop"]
  },
  {
    name: "VR Rocket Travel",
    description: "Meta Quest application, allows you to fly with jetpacks.",
    imgUrl: rocketvr,
    videoUrl: rocketvrvideo,
    link: "https://github.com/DenizYunus/JetFlyQuests-Quest",
    tags: ["XR", "Quest"]
  },
  {
    name: "Weight/Health App",
    description: "Kinect controlled, health informative application, showing the relation between weight and diseases.",
    imgUrl: weightapp,
    videoUrl: weightappvideo,
    link: "",
    tags: ["Mobile", "Kinect"]
  },
  {
    name: "Tunnel App",
    description: "Arduino PIR sensors activated video tunnel application showing external loaded videos on 2 different monitors.",
    imgUrl: tunnelapp,
    videoUrl: tunnelappvideo,
    link: "",
    tags: ["Desktop", "Hardware"]
  },
  {
    name: "XR Huge Web Browser",
    description: "Passthrough supported, Hand Tracking & Controller supported huge XR Web Browser Application.",
    imgUrl: vastview,
    videoUrl: vastviewvideo,
    link: "https://github.com/DenizYunus/VastView",
    tags: ["XR", "Quest"]
  },
  {
    name: "Kinect Skeleton App",
    description: "Kinect Controlled, Skeleton Shaped Motion Tracking Application.",
    imgUrl: skeleton,
    videoUrl: skeletonvideo,
    link: "",
    tags: ["Desktop", "Kinect", "Motion Capture"]
  },
  {
    name: "Scan&Gift App",
    description: "Bentley Tobacco app for scanning cigarattes and giving gift packets according to remaining stocks.",
    imgUrl: cigarattesapp,
    videoUrl: cigarattesappvideo,
    link: "",
    tags: ["Mobile", "AI", "Hostess"]
  },
  {
    name: "VR Chicken Saber",
    description: "A VR app inspired by Beat Saber and Fruit Ninja, fusioned and Chicken Saber is created, SLICE THE CHICKENS!",
    imgUrl: chickensaber,
    videoUrl: chickensabervideo,
    link: "",
    tags: ["XR", "Quest", "Beat Saber Style"]
  },
  {
    name: "Coke Bubbles Speech App",
    description: "Speech Recognition app where you tell your name, and it gets shaped with bubbles and saved to database using local TTS model.",
    imgUrl: colabubbles,
    videoUrl: colabubblesvideo,
    link: "",
    tags: ["Speech Rec.", "Particle Show"]
  },
  {
    name: "VR Tilt (Arm Workout)",
    description: "A VR Cardio App that combines the use of shoulders and arms actively.",
    imgUrl: tilt,
    videoUrl: tiltvideo,
    link: "",
    tags: ["XR", "Quest", "Health/Fitness"]
  },
  {
    name: "Photo Booth, Smile (:",
    description: "A photo booth app using Canon Camera SDK and prints the photo with some edits.",
    imgUrl: iqoscanon,
    videoUrl: iqoscanonvideo,
    link: "",
    tags: ["Canon Camera", "Photo Upload"]
  },
  {
    name: "Cancer Psychology App",
    description: "People score about cancer and its psychologic facts, and these scores combined in a pool and showed in the end with percentages.",
    imgUrl: iqoscancer,
    videoUrl: iqoscancervideo,
    link: "",
    tags: ["Data Analysis", "Health"]
  },
  {
    name: "Smart Flower Pot",
    description: "A flower pot system designed with Arduino, with custom rules for watering and sending notifications, checking temp, moist, light etc. values.",
    imgUrl: smartpot,
    link: "https://github.com/DenizYunus/Smart-Flower-Pot-Controller",
    tags: ["Arduino", "Agriculture"]
  },
  {
    name: "Calendar & Task Planner",
    description: "Google Calendar integrated, task planning app using Unity for a startup. Made with Unity with frameless windows, solely Unity and C# backend.",
    imgUrl: octo,
    link: "https://github.com/DenizYunus/Google-Calendar-Integrated-Desktop-Startup-Project",
    tags: ["Productivity", "Desktop"]
  },
  {
    name: "Windows 95 - Barbie Themed",
    description: "A fun React project with a sample Windows features styled with Barbie theme. Has multi windows support, minimizing and restoring windows, textbox etc.",
    imgUrl: barbiewindows,
    videoUrl: barbiewindowsvideo,
    link: "https://zingy-tanuki-e87ceb.netlify.app/",
    tags: ["Web"]
  },
  {
    name: "Boxing App",
    description: "An Arduino assisted boxing apps where user punches the boxing rack, and gets health suggestions after each punch.",
    imgUrl: boxing,
    link: "",
    tags: ["Arduino", "Fitness"]
  },
  {
    name: "Cancer Note Taking App",
    description: "For a health fair, an application shown on a big touch screen letting people share their thoughts about the product with some pre-defined keywords.",
    imgUrl: cancernotetaking,
    link: "",
    tags: ["Health"]
  },
  {
    name: "Coke Backgroundless Printer",
    description: "An app that removes the background of the photo you took inside the app, and prints it with some additional stickers.",
    imgUrl: colabackgroundprint,
    link: "",
    tags: ["Background Removal", "Printer"]
  },
]